<template>
  <div>
    <h1 class='text-2xl mb-16'>
      {{reportName}}
    </h1>
    <div class='mb-12'>
      <a class='hover:bg-gray-100 rounded-md py-2 px-8 border' :href='announcementLink' target='_blank'>Go To Dart Page</a>
      <button class='hover:bg-gray-100 rounded-md ml-4 py-2 px-8 border' @click='createInvestmentCard'>Card생성</button>
    </div>
    <div class='mb-12'>
      <label class='form-label'>종목</label>
      <div>{{drilldownCorporateAnnouncement.name}} ({{drilldownCorporateAnnouncement.share_code}})</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>진행상황</label>
      <div>{{drilldownCorporateAnnouncement.details['진행상황']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>시장구분</label>
      <div>{{drilldownCorporateAnnouncement.details['시장구분']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>상장공모</label>
      <div>{{listedPublicOffering}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>총공모주식수</label>
      <div>{{drilldownCorporateAnnouncement.details['총공모주식수']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>액면가</label>
      <div>{{drilldownCorporateAnnouncement.details['액면가']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>희망공모가액</label>
      <div>{{drilldownCorporateAnnouncement.details['희망공모가액']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>확정공모가</label>
      <div>{{drilldownCorporateAnnouncement.details['확정공모가']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>주간사</label>
      <div>{{drilldownCorporateAnnouncement.details['주간사']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>수요예측일</label>
      <div>{{drilldownCorporateAnnouncement.details['수요예측시작일']}} ~ {{drilldownCorporateAnnouncement.details['수요예측종료일']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>공모청약일</label>
      <div>{{drilldownCorporateAnnouncement.details['공모청약시작일']}} ~ {{drilldownCorporateAnnouncement.details['공모청약종료일']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>납입일</label>
      <div>{{drilldownCorporateAnnouncement.details['납입일']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>환불일</label>
      <div>{{drilldownCorporateAnnouncement.details['환불일']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>상장일</label>
      <div>{{drilldownCorporateAnnouncement.details['상장일']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>Receipt Date</label>
      <div>{{drilldownCorporateAnnouncement.receipt_date}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>Receipt Number</label>
      <div>{{drilldownCorporateAnnouncement.receipt_number}}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CorporateAnnouncementIpoDetails',
  computed: {
    reportName () {
      return this.drilldownCorporateAnnouncement.report_name
    },
    ...mapState('corporateAnnouncements', [
      'drilldownCorporateAnnouncement',
    ]),
    ...mapState('entities', [
      'entities'
    ]),
    announcementLink () {
      return this.drilldownCorporateAnnouncement.url ? this.drilldownCorporateAnnouncement.url : ''
    },
    newShares () {
      return !this.drilldownCorporateAnnouncement.details['신주모집'] ? '' : '신주: ' + this.drilldownCorporateAnnouncement.details['신주모집'] + ' % '
    },
    oldShares () {
      return !this.drilldownCorporateAnnouncement.details['구주매출'] ? '' : '구주: ' + this.drilldownCorporateAnnouncement.details['구주매출'] + ' %'
    },
    listedPublicOffering () {
      return this.newShares + this.oldShares
    },
  },
  methods: {
    ...mapActions('entities', [
      'createEntityWithName',
      'getEntities',
    ]),
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('investments', [
      'createInvestment',
    ]),
    createInvestmentCard () {
      // TODO: get smarter and dont recreate cards even on announcement updates
      // and smarter entity finding
      let investment = {}
      if (this.entity === undefined) {
        this.createEntityWithName(this.drilldownCorporateAnnouncement.name).then(resp => {
          investment.investment_name = resp.entity_name + ' - ' + this.drilldownCorporateAnnouncement.asset_class
          investment.entity_id = resp.id
          this.createInvestment(investment)
        })
      } else {
        investment.investment_name = this.entity.entity_name + ' - ' + this.drilldownCorporateAnnouncement.asset_class
        investment.entity_id = this.entity.id
        this.createInvestment(investment)
      }
    },
    doneEditing () {
      this.sidepanelClose()
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
  },
  mounted () {
    this.getEntities()
  }
}
</script>

<style lang='scss' scoped>

.form-label {
  @apply block font-normal mb-2 text-gray-600 text-sm tracking-wide uppercase;
}


</style>
